<template>
    <div class="tabs">
        <div class="tab-list">
            <div class="tab-item" :ref="item.name" @click="tabClick(item,$event)" v-for="(item,index) in tabs"
                 :key="index" :class="item.name===activeItem?'is-active':''">
                <span>{{item.name}}</span>
            </div>
        </div>
        <slot>

        </slot>
    </div>
</template>

<script>
    export default {
        name: "tabs",
        props: {
            tabs: {
                type: Array,
                default: []
            },
            defaultItem: {
                type: String
            }
        },
        data() {
            return {
                activeStyle: {},
                activeItem: ''
            }
        },
        mounted() {
            this.activeItem = this.defaultItem || this.tabs[0].name
            let target = this.$refs[this.activeItem][0]
            let offsetX = target.offsetLeft
            let offsetWidth = target.offsetWidth
            this.activeStyle = {
                transform: `translateX(${offsetX}px)`,
                width: `${offsetWidth}px`
            }
        },
        watch: {
            defaultItem(val){
                this.activeItem = val
            }
        },
        methods: {
            tabClick(item, e) {
                let offsetX = e.target.offsetLeft
                let offsetWidth = e.target.offsetWidth
                this.activeStyle = {
                    transform: `translateX(${offsetX}px)`,
                    width: `${offsetWidth}px`
                }
                this.activeItem = item.name
                this.$emit('tab-click', item)
            }
        }
    }
</script>

<style scoped lang="less">
    .tabs {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 10px;
        .tab-list {
            display: flex;
            align-items: center;
            .tab-item {
                position: relative;
                width: 104px;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #dcdfe6;
                border-right: 0;
                color: #303133;
                &:first-child {
                    /*border-left: 1px solid #dcdfe6;*/
                    border-bottom-left-radius: 4px;
                    border-top-left-radius: 4px;
                }
                &:last-child {
                    border-right: 1px solid #dcdfe6;
                    border-bottom-right-radius: 4px;
                    border-top-right-radius: 4px;
                }
                &:hover {
                    color: #53a5ff;
                }
                &.is-active {
                    color: #53a5ff;
                    background-color: rgba(83, 165, 255, 0.1);
                    border: solid 1px #a0cdff;
                }
                &.is-active + .tab-item {
                    border-left: 0;
                }
            }
        }
    }
</style>
