<template>
  <el-container class="page-container">
    <el-header class="page-header" style="min-height: 60px;height: auto">
      <el-form label-position='left' :model="query" label-width="85px" ref="searchForm">
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="8" :lg="8">
            <el-form-item prop="time" label="筛选时间">
              <el-date-picker
                  class="inner-input"
                  @change="handleChangeSiteID"
                  format="yyyy-MM-dd "
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="query.time"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00','23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="8">
            <el-form-item label-width="0">
              <el-button type="primary" @click="handleChangeSiteID">
                <i class="cw-icon cw-icon-sousuo"></i>搜索
              </el-button>
              <!--<el-button type="primary" plain @click="clearSearch">-->
              <!--<i class="cw-icon cw-icon-zhongzhi"></i>重置-->
              <!--</el-button>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-header>
    <el-main class="page-main" style="padding: 0 20px">
      <br>
      <div class="statistical-container">
        <div class="statistical-item">
          <el-card>
            <h3>总收益(元)</h3>
            {{ saleTotal.totalfee }}
          </el-card>
        </div>
        <div class="statistical-item">
          <el-card>
            <h3>管理员收益(元)</h3>
            {{ saleTotal.managefee }}
          </el-card>
        </div>
        <div class="statistical-item">
          <el-card>
            <h3>菜品数(份)</h3>
            {{ saleTotal.goodsnum }}
          </el-card>
        </div>
        <div class="statistical-item">
          <el-card>
            <h3>订单数(单)</h3>
            {{ saleTotal.ordercount }}
          </el-card>
        </div>

        <div class="statistical-item">
          <el-card>
            <h3>已返点(元)</h3>
            {{ saleTotal.backed }}
          </el-card>
        </div>
        <div class="statistical-item">
          <el-card>
            <h3>未返点(元)</h3>
            {{ saleTotal.unback }}
          </el-card>
        </div>
      </div>
      <br>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card>
            <div class="order-statistical" ref="orderChart" style="height: 280px;background-color: #ffffff"></div>
          </el-card>
        </el-col>
      </el-row>
      <br>
      <el-row :gutter="20">

        <el-col :span="24">
          <el-card class="details">
            <div slot="header" class="clearfix">
              <span>特殊账号订单统计统计</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-input readonly v-model="specialsales.ordercount">
                  <template slot="prepend">订单总量</template>
                </el-input>
              </el-col>
              <el-col :span="12">
                <el-input readonly v-model="specialsales.goodsnum">
                  <template slot="prepend">菜品总量</template>
                </el-input>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <br>
      <el-row :gutter="20">

        <el-col :span="12">
          <el-card>
            <div slot="header" class="clearfix">
              资金明细

            </div>
            <el-tabs type="border-card">
              <el-tab-pane class="details" :label="item.name" v-for="(item,index) in capitalSubsidiary"
                           :key="index">
                <ul class="list">
                  <li class="item">
                    <div class="label">总收益</div>
                    <div class="text">{{ item.orderFee }}元</div>
                  </li>
                  <li class="item">
                    <div class="label">总订单数</div>
                    <div class="text">{{ item.orderCount }}单</div>
                  </li>
                  <li class="item">
                    <div class="label">菜品份数</div>
                    <div class="text">{{ item.goodsNum }}份</div>
                  </li>
                  <li class="item">
                    <div class="label">已返点</div>
                    <div class="text">{{ item.backed }}元</div>
                  </li>
                  <li class="item">
                    <div class="label">未返点</div>
                    <div class="text">{{ item.unback }}元</div>
                  </li>
                </ul>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>

        <el-col :span="12">
          <el-card>
            <div slot="header" class="clearfix">
              菜品销量统计
              <el-input size="small" style="width: 240px" placeholder="请输入菜名" v-model="goodsName">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <el-table
                max-height="295"
                :data="filterGoods"
                style="width: 100%"
            >
              <el-table-column
                  prop="goodname"
                  label="菜品名称"
              >
              </el-table-column>
              <el-table-column
                  prop="fee"
                  label="菜品单价"
              >
              </el-table-column>
              <el-table-column
                  prop="goodnum"
                  label="菜品份数">
              </el-table-column>
            </el-table>

          </el-card>
        </el-col>

      </el-row>
      <br>
      <el-row :gutter="20">

        <el-col :span="12">
          <el-card class="details">
            <div slot="header" class="clearfix">
              <span>网吧用户订单统计</span>
              <el-input size="small" style="width: 240px" placeholder="请输入网吧名称" v-model="internetBarName">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <div style="height: 500px;overflow-x: hidden">
              <el-collapse>
                <el-collapse-item class="collapse" :name="item.id"
                                  v-for="(item,index) in filterInernetBars" :key="index">
                  <template slot="title">
                    <div style="flex:1;display: flex;justify-content: space-between">
                      <div>
                        {{ item.name }}
                      </div>
                      <div>
                        <el-tag style="margin:0 10px">订单总额 {{ item.totalfee }}</el-tag>
                        <el-tag>菜品份数 {{ item.totalnum }}</el-tag>
                        <el-tag style="margin:0 10px" :type="item.type===1?'success':'info'">
                          {{ item.type === 1 ? '已返点' : '未返点' }}
                          ￥{{ item.backfee }}
                        </el-tag>
                      </div>
                    </div>
                  </template>

                  <ul class="list">
                    <li class="item" v-for="(good,idx) in item.goods" :key="idx">
                      <div class="label">{{ good.goodname }}</div>
                      <div class="text">{{ good.fee }}元/份 {{ good.goodnum }}份</div>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="details">
            <div slot="header" class="clearfix">
              <span>员工订单统计</span>
              <el-input size="small" style="width: 240px" placeholder="请输入员工名称" v-model="staffSaleName">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <div style="height: 500px;overflow-x: hidden">

              <el-table
                  max-height="500"
                  :data="filterAllStaffSalesArr"
                  style="width: 100%"
              >
                <el-table-column
                    prop="nickname"
                    label="员工名称"
                >
                </el-table-column>
                <el-table-column
                    prop="shopname"
                    label="网吧"
                >
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="订单数">
                </el-table-column>
                <el-table-column
                    prop="fee"
                    label="总金额">
                </el-table-column>
              </el-table>


              <!--<el-collapse>-->
              <!--<el-collapse-item class="collapse" :name="item.id"-->
              <!--v-for="(item,index) in filterAllStaffSalesArr" :key="index">-->
              <!--<template slot="title">-->
              <!--<div style="flex:1;display: flex;justify-content: space-between">-->
              <!--<div>-->
              <!--{{item.name}}-->
              <!--</div>-->
              <!--<div>-->
              <!--<el-tag style="margin:0 10px">订单总额 {{item.totalfee}}</el-tag>-->
              <!--<el-tag>菜品份数 {{item.totalnum}}</el-tag>-->
              <!--<el-tag style="margin:0 10px" :type="item.type===1?'success':'info'">-->
              <!--{{item.type===1?'已返点':'未返点'}}-->
              <!--￥{{item.backfee}}-->
              <!--</el-tag>-->
              <!--</div>-->
              <!--</div>-->
              <!--</template>-->

              <!--<ul class="list">-->
              <!--<li class="item" v-for="(good,idx) in item.goods" :key="idx">-->
              <!--<div class="label">{{good.goodname}}</div>-->
              <!--<div class="text">{{good.fee}}元/份 {{good.goodnum}}份</div>-->
              <!--</li>-->
              <!--</ul>-->
              <!--</el-collapse-item>-->
              <!--</el-collapse>-->
            </div>
          </el-card>
        </el-col>

      </el-row>
      <br>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="details">
            <div slot="header" class="clearfix">
              <span>物料统计</span>
              <el-input size="small" style="width: 240px" placeholder="请输入物料名称" v-model="materialName">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <div style="height: 500px;overflow-x: hidden">
              <el-collapse>
                <el-collapse-item class="collapse" :name="item.id"
                                  v-for="(item,index) in filterMaterials" :key="index">
                  <template slot="title">
                    {{ item.name }}
                    <el-tag style="margin: 0 10px">菜品份数 {{ item.total }}</el-tag>
                  </template>
                  <ul class="list">
                    <li class="item" v-for="(good,idx) in item.goods" :key="idx">
                      <div class="label">{{ good.goodname }}</div>
                      <div class="text">{{ good.goodnum }}份</div>
                    </li>
                  </ul>
                </el-collapse-item>
              </el-collapse>
            </div>

          </el-card>
        </el-col>

      </el-row>
      <br>
    </el-main>
  </el-container>

</template>

<script>
import tabs from '../../components/tabs'
import * as foodManager from '../../api/foodManager'
import {foodSiteData} from "../../api/storeArea";
import moment from 'moment'
import Echarts from 'echarts'

let startTime = moment(new Date(new Date().toLocaleDateString()).getTime()).format('YYYY-MM-DD HH:mm:ss'); // 当天0点
let endTime = moment(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1).format('YYYY-MM-DD HH:mm:ss');// 当天
export default {
  name: "materialStatistical",
  components: {tabs},
  data() {
    return {
      query: {time: [startTime, endTime]},
      saleTotal: {},
      foodsSales: [],
      capitalSubsidiary: [],
      materials: [],
      inernetBars: [],
      storeAre: [],
      allStaffSalesArr: [],
      specialsales: {
        goodsnum: 0,
        ordercount: 0
      },
      goodsName: '',
      internetBarName: '',
      materialName: '',
      staffSaleName: ''
    }
  },
  computed: {
    filterGoods() {
      if (this.goodsName) {
        return this.foodsSales.filter(good => good.goodname.indexOf(this.goodsName) >= 0)
      } else {
        return this.foodsSales
      }
    },
    filterMaterials() {
      if (this.materialName) {
        return this.materials.filter(material => material.name.indexOf(this.materialName) >= 0)
      } else {
        return this.materials
      }
    },
    filterInernetBars() {
      if (this.internetBarName) {
        return this.inernetBars.filter(inernetBar => inernetBar.name.indexOf(this.internetBarName) >= 0)
      } else {
        return this.inernetBars
      }
    },
    filterAllStaffSalesArr() {
      if (this.staffSaleName) {
        return this.allStaffSalesArr.filter(staffSale => staffSale.nickname.indexOf(this.staffSaleName) >= 0)
      } else {
        return this.allStaffSalesArr
      }
    }
  },
  methods: {
    clearSearch() {
      this.$refs.searchForm.resetFields();
      this.handleChangeSiteID()
    },
    materialsChart() {
      let materialsChart = echarts.init(document.querySelector('#materials'));

    },
//菜品销量统计
    async statisFoodsales() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.statisFoodsales(formData)
        this.foodsSales = data
        console.log(data)
      } catch (e) {

      }
    },

//物料统计：
    async statisMaterialspends() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.statisMaterialspends(formData)
        this.materials = data
        console.log(data)
      } catch (e) {

      }
    },

//资金总览：
    async statisTotalsales() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.statisTotalsales(formData)
        this.saleTotal = data
      } catch (e) {

      }
    },

//资金明细：
    async statisSaledetails() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.statisSaledetails(formData)
        this.capitalSubsidiary = data
      } catch (e) {

      }
    },
//资金明细：
    async allStaffSales() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.allStaffSales(formData)
        this.allStaffSalesArr = data
      } catch (e) {

      }
    },
    //网吧订单统计
    async statisBarsales() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.statisBarsales(formData)
        this.inernetBars = data
      } catch (e) {

      }
    },
    //网吧订单统计
    async statisSpecialsales() {
      try {
        let time = this.query.time
        let formData = {
          start: time[0], end: time[1]
        }
        let {data} = await foodManager.statisSpecialsales(formData)
        this.specialsales = data
      } catch (e) {

      }
    },
    async getOrderScale() {
      try {
        let time = this.query.time
        let formData = {
          start: new Date(time[0]).getTime(), end: new Date(time[1]).getTime()
        }
        let {data} = await foodManager.orderScales(formData)
        data = data || [];
        let xAxiData = [];
        let orderData = [];
        let amountData = [];
        let goodsData = [];
        data.map(item => {
          xAxiData.push(item.time)
          amountData.push(item.amount)
          goodsData.push(item.goodsNum)
          orderData.push(item.orderNum)
        })
        let option = {
          color: ['#53a5ff','#67C23A','#F56C6C'],
          grid:{
            left:'50',
            right:'50'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#283b56'
              }
            }
          },
          legend: {
            data: ['销售总额', '订单数', '菜品数']
          },
          dataZoom: {
            show: true,
            start: 0,
            end: 50
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: true,
              data: xAxiData
            }
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              name: '元',
              splitLine:{
                show:false
              }
            },
            {
              type: 'value',
              scale: true,
              splitLine:{
                show:false
              }
            }
          ],
          series: [
            {
              name: '销售总额',
              type: 'line',

              data: amountData
            },
            {
              yAxisIndex: 1,
              name: '订单数',
              type: 'bar',
              barWidth:20,
              data: orderData
            },
            {
              yAxisIndex: 1,
              name: '菜品数',
              type: 'bar',
              barWidth:20,
              data: goodsData
            }
          ]
        };
        let orderCharts = Echarts.init(this.$refs.orderChart);
        orderCharts.setOption(option)
        window.onresize = ()=>{
          orderCharts.resize()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async foodSiteData() {
      try {
        let {data} = await foodSiteData()
        this.storeAre = data.list
      } catch (e) {

      }
    },
    handleChangeSiteID() {
      this.statisFoodsales()
      this.statisMaterialspends()
      this.statisTotalsales()
      this.statisSaledetails()
      this.statisBarsales()
      this.statisSpecialsales()
      this.allStaffSales()
      this.getOrderScale()
    }
  },
  async created() {
    this.foodSiteData()
    this.handleChangeSiteID()
  }
}
</script>

<style lang="less">
.statistical-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .statistical-item {
    flex: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.clearfix {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details {
  .collapse {
    .list {
      padding-right: 30px;
      box-sizing: border-box;
    }
  }

  .list {
    list-style: none;
    padding: 0;

    .item {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        color: #999999;
      }

      .text {
        color: #222222;
        font-weight: bold;
      }
    }
  }
}
</style>
